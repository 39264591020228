const IS = "=="
const ISNOT = "!="
const TRUE = "TRUE"
const FALSE = "FALSE"
const AND = "AND"
const OR = "OR"
const MATCHES = "MATCHES"
const CONTAINS = "CONTAINS"
const NOTCONTAINS = "!CONTAINS"

const integer_types = ["sensor", "rating", "slider", "likert"]
const option_types = ["list", "multi"]
const regex_types = ["text", "short"]
const comparison_operators = [">", "<", ">=", "<=", IS, ISNOT, CONTAINS, NOTCONTAINS]

export const validateExpression = (x, variables) => {
  if (Object.keys(x).length === 0) {
    return false
  }

  for (const expression of x) {
    if (expression) {
      try {
        const tree = buildExpressionTree(expression, variables)
      } catch (e) {
        return false
      }
    }
  }
  return true
}

export const evaluateExpression = (responses, expression) => {
  // Given a list of responses and the expression,
  // evaluates each response on the expression and returns Boolean

  const tree = jsonStringToExpressionTree(expression)

  // Prepare variable object to evaluate the expression tree with
  // NOTE: keys are human_id's and values are boolean values or (in case of multiselect) array of string responses
  const variables = responses.reduce((acc, response) => {
    if (!response) return acc

    const { human_id, question_type, temporal_slices, options } = response

    let value: any

    switch (question_type) {
      // NOTE: fall throughs are explicit
      case "boolean":
        value = temporal_slices[0].value
        acc[human_id] = value === "Yes"
        break

      case "list":
      case "multiselect":
        // Parse the multiselect response
        const values =
          question_type === "multiselect"
            ? temporal_slices[0].value.split('"').filter((x) => x !== "")
            : temporal_slices[0].value

        if (options) {
          options.forEach((option) => {
            value = options.find((v) => v.value === option.value)

            if (values.includes(option.value)) {
              // This answer substitutes to true
              acc[value.answer_id] = true

              if (!acc[value.question_id]) {
                acc[value.question_id] = []
              }
              // Add answers that evaluate to true
              acc[value.question_id].push(value.answer_id)
            } else {
              acc[value.answer_id] = false
            }
          })
        }
        break

      case "rating":
      case "slider":
        options.forEach((option) => {
          value = temporal_slices[0].value

          if (option.value === value) {
            // This answer substitutes to true
            acc[String(option.value)] = option.value

            // Add answer value with question key
            acc[option.question_id] = temporal_slices[0].value
          }
        })
        break

      case "time":
        value = temporal_slices[0].value
        acc[human_id] = parseTime(value)
        break

      case "short":
      case "text":
        value = temporal_slices[0].value
        acc[human_id] = value
        break

      case "likert":
        value = temporal_slices[0].value
        acc[human_id] = value
        break
    }

    return acc
  }, {})

  return evaluateExpressionTree(tree, variables)
}

export const extractConditions = (question, activities) => {
  const e = question.expression
  const tree = buildExpressionTree(e, activities)
  return tree
}

// Tree Node
class Node {
  value: Node | object | string
  left: Node | null
  right: Node | null
  constructor(value: Node | object | string) {
    this.value = value
    this.left = null
    this.right = null
  }
}

const buildExpressionTree = (expression, activities) => {
  const operators = [AND, OR, IS, ISNOT, ">", "<", ">=", "<=", MATCHES, CONTAINS, NOTCONTAINS] // '>', '<', '>=', '<=', 'NOT'
  const precedence = {
    AND: 1,
    OR: 1,
    "==": 2,
    "!=": 2,
    ">": 2,
    "<": 2,
    ">=": 2,
    "<=": 2,
    MATCHES: 2,
    CONTAINS: 2,
    NOTCONTAINS: 2,
    // NOT: 3,
  }

  const outputQueue = []
  const operatorStack = []
  const tokens = expression.split(" ")

  for (let token of tokens) {
    if (!operators.includes(token) && token !== "(" && token !== ")") {
      outputQueue.push(new Node(token))
    } else if (operators.includes(token)) {
      while (
        operatorStack.length > 0 &&
        operators.includes(operatorStack[operatorStack.length - 1]) &&
        precedence[operatorStack[operatorStack.length - 1]] >= precedence[token]
      ) {
        outputQueue.push(new Node(operatorStack.pop()))
      }
      operatorStack.push(token)
    } else if (token === "(") {
      operatorStack.push(token)
    } else if (token === ")") {
      while (operatorStack.length > 0 && operatorStack[operatorStack.length - 1] !== "(") {
        outputQueue.push(new Node(operatorStack.pop()))
      }
      operatorStack.pop() // TODO: Remove the '(' and ')'
    }
  }

  while (operatorStack.length > 0) {
    outputQueue.push(new Node(operatorStack.pop()))
  }

  // Build the expression tree from the output queue.
  const treeStack = []
  for (let item of outputQueue) {
    if (!operators.includes(item.value)) {
      treeStack.push(item)
    } else {
      const right = treeStack.pop()
      const left = treeStack.pop()
      const node = new Node(item.value)

      if (!(left.left && left.right)) {
        // Leaf node, contains question specific information
        const valueLeft =
          left.value !== "" ? activities.find((activity) => activity.human_id === left.value) : undefined

        if (!valueLeft) throw new Error("Invalid human_id: " + left.value)
        const nodeValue = {
          id: valueLeft.id,
          human_id: valueLeft.human_id,
          type: valueLeft.type,
          options: valueLeft.options ?? undefined,
        }
        const leaf = new Node(nodeValue) // FIXME: might need to stringify nodeValue
        node.left = leaf
      } else {
        node.left = left
      }

      // Type checking
      if (typeof node.left.value === "object" && "type" in node.left.value) {
        // Integers
        if (
          integer_types.includes(node.left.value["type"] as string) &&
          (!comparison_operators.includes(item.value) || !isInteger(right.value))
        ) {
          throw new Error("Invalid expression: " + right.value + " is not a number.")
        }
        // Regex
        if (
          regex_types.includes(node.left.value["type"] as string) &&
          (item.value !== MATCHES || !parseRegex(right.value as string))
        ) {
          throw new Error("Invalid regex expression: " + right.value)
        }
        // Timestamps
        if (
          node.left.value["type"] === "time" &&
          (!comparison_operators.includes(item.value) || parseTime(right.value as string) === null)
        ) {
          throw new Error("Invalid expression: " + right.value + " is not a timestamp.")
        }
        // Variables
        if (
          option_types.includes(node.left.value["type"] as string) &&
          "options" in node.left.value &&
          ((item.value !== IS && item.value !== ISNOT) ||
            (!(node.left.value["options"] as string[]).includes(right.value) && // options as array of strings
             !(node.left.value["options"] as any[]).some(option => option["human_id"] === right.value)) // options as array of objects
          )
        ) {
          throw new Error("Invalid expression: " + right.value + " is not a valid option.")
        }
      }

      node.right = right

      treeStack.push(node)
    }
  }

  if (treeStack.length !== 1) {
    throw new Error("Invalid expression")
  }

  return treeStack[0]
}

const evaluateExpressionTree = (root, variables) => {
  if (root === null) {
    return true // An empty expression is considered true.
  }

  if (!root.left && !root.right) {
    if (root.value === TRUE) return true
    if (root.value === FALSE) return false
    // If the node is a leaf, it represents a variable.
    // Look up the variable's value in the provided `variables` object.

    let variableValue: any = null

    if (isFloat(root.value)) {
      // If numeric value, parse
      variableValue = parseFloat(root.value)
    }

    if (variableValue === null) {
      // If time value, returns null otherwise
      variableValue = parseTime(root.value)
    }

    if (variableValue === null) {
      // Try parsing a regex
      variableValue = parseRegex(root.value)
    }

    if (variableValue === null) {
      // Try to look up the variable's value in the provided `variables` object
      variableValue = root.value.human_id ? variables[root.value.human_id] : variables[String(root.value)]
    }

    if (variableValue === null) {
      throw new Error(`Variable ${root.value} is not defined.`)
    }

    return variableValue
  }

  // Recursively evaluate the left and right subtrees.
  const leftValue = evaluateExpressionTree(root.left, variables)
  const rightValue = evaluateExpressionTree(root.right, variables)

  // Perform the operation based on the operator in the current node.
  switch (root.value) {
    case AND:
      return leftValue && rightValue
    case OR:
      return leftValue || rightValue
    case IS:
      return leftValue === rightValue
    case ISNOT:
      return leftValue !== rightValue
    case ">":
      return leftValue > rightValue
    case "<":
      return leftValue < rightValue
    case ">=":
      return leftValue >= rightValue
    case "<=":
      return leftValue <= rightValue
    case MATCHES:
      return leftValue.match(rightValue)
    case CONTAINS:
      return leftValue.includes(rightValue)
    case NOTCONTAINS:
      return !leftValue.includes(rightValue)
    // case 'NOT':
    //   return !rightValue;
    default:
      throw new Error(`Invalid operator: ${root.value}`)
  }
}

// MARK: Trigger evaluation
const validateAction = (action, n_blocks, surveys) => {
  // Check if the action is a number (index) or a valid StartSurvey call
  if (!isNaN(action) && action > 0 && action < n_blocks) {
    return true
  }
  const match = action.match(/^StartSurvey\(([\w\s]+)\)$/)
  if (match) {
    const surveyName = match[1]
    return surveys.includes(surveyName)
  }
  return false
}

const validateBlock = (block, n_blocks, surveys, variables) => {
  const parts = block.split(",")
  if (parts.length < 2 || parts.length > 3) {
    return false
  }

  const [expression, actionTrue, actionFalse] = parts.map((part) => part.trim())

  // Check for empty strings
  if (!expression || !actionTrue || (parts.length === 3 && !actionFalse)) {
    return false
  }

  if (!validateExpression([expression], variables)) {
    return false
  }
  if (!validateAction(actionTrue, n_blocks, surveys)) {
    return false
  }
  if (actionFalse && !validateAction(actionFalse, n_blocks, surveys)) {
    return false
  }
  return true
}

export const validateTrigger = (input, surveys, variables) => {
  const blocks = input.match(/{[^{}]*}/g)
  if (!blocks) {
    return false
  }
  return blocks.every((block) => validateBlock(block.slice(1, -1).trim(), blocks.length, surveys, variables))
}

// MARK: Conversion methods
export const expressionTreeToString = (root) => {
  if (root === null) {
    return ""
  }

  if (root.left === null && root.right === null) {
    return root.value
  }

  let leftExpression = expressionTreeToString(root.left)
  let rightExpression = expressionTreeToString(root.right)

  // Handle precedence of operators.
  const precedence = {
    AND: 1,
    OR: 1,
    "==": 2,
    "!=": 2,
    ">": 2,
    "<": 2,
    ">=": 2,
    "<=": 2,
    MATCHES: 2,
    NOT: 3,
  }

  if (root.value in precedence) {
    // If the current operator has lower precedence than its children, wrap it in parentheses.
    if (root.left !== null && root.left.value in precedence && precedence[root.value] > precedence[root.left.value]) {
      leftExpression = `(${leftExpression})`
    } else if (root.left !== null && typeof root.left.value === "object" && root.left.value.human_id) {
      // If the node is a leaf, it represents a variable.
      leftExpression = leftExpression.human_id
    }

    if (
      root.right !== null &&
      root.right.value in precedence &&
      precedence[root.value] > precedence[root.right.value]
    ) {
      rightExpression = `(${rightExpression})`
    }
  }

  return `${leftExpression} ${root.value} ${rightExpression}`
}

export const jsonStringToExpressionTree = (string) => {
  return JSON.parse(string, (key, value) => {
    if (value && (key === "left" || key === "right")) {
      return typeof value === "object" ? value : new Node(value.value)
    }
    return value
  })
}

const parseTime = (value: string) => {
  if (typeof value !== "string" || !value.match(/(\d+)\:(\d+)(\w+)/)) {
    return null
  }

  let hours, minutes: number
  if (value.includes("AM") || value.includes("PM")) {
    const parts = value.match(/(\d+)\:(\d+)(\w+)/)
    hours = /am/i.test(parts[3]) ? parseInt(parts[1], 10) : parseInt(parts[1], 10) + 12
    minutes = parseInt(parts[2], 10)
  } else {
    const parts = value.match(/(\d+)\:(\d+)/)
    hours = parseInt(parts[1], 10)
    minutes = parseInt(parts[2], 10)
  }

  return hours + minutes / 60
}

const parseRegex = (str: string) => {
  if (typeof str !== "string") {
    return null
  }

  const regexMatch = str.match(/^\/(.*)\/([gimy]*)$/)
  if (!regexMatch) {
    return null
  }

  const [, pattern, flags] = regexMatch
  const regex = new RegExp(pattern, flags)

  return regex
}

function isFloat(value: any): boolean {
  const floatValue = parseFloat(value)
  return !isNaN(floatValue)
}

function isInteger(str: string): boolean {
  const num = parseInt(str, 10)
  return !isNaN(num) && num.toString() === str
}
