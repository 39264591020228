import SPARK from "spark-core"
import i18n from "../../../../i18n"
import { Service } from "../../../DBService/DBService"
import { games } from "./Activity"
import { expressionTreeToString, extractConditions, jsonStringToExpressionTree } from "./ExpressionMethods"
export const SchemaList = (items?: any) => {
  let _items
  if (items && Object.keys(items).length === 0) {
    // In case items variable is an object
    _items = []
  } else {
    _items = items ?? []
  }

  const conditionals = _items.filter((s) => s.type === "boolean") // TODO: support other question types
  // TODO: how can we fix not allowing to select itself as a conditional??? (tentative below?)
  // let conditionals = {}
  // for (let i = 0; i < _items.length; i++) {
  //   // FIXME: refactor this to use question ID's instead (once implemented)
  //   const currentItem = _items[i]
  //   const previousQuestions = _items.slice(0, i)

  //   const possibleConditionals = previousQuestions.filter((s) => s.type === "boolean") // TODO: support other question types

  //   // Set conditionals for this question (where question.text is the key, and possibleConditionals is the value)
  //   conditionals[currentItem.text] = possibleConditionals
  // }

  // FIXME: show "None configured" when no hidden-activities are configured
  // FIXME: should we ask for optional hidden surveys, when survey itself is a hidden survey?
  return {
    "spark.balloon_risk": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
          required: ["balloon_count", "breakpoint_mean", "breakpoint_std"],
          properties: {
            balloon_count: {
              title: i18n.t("Balloon Count"),
              description: i18n.t("The number of balloons to display."),
              type: "number",
              default: 15,
              minimum: 1,
              maximum: 100,
              "ui:grid": {
                xs: 4,
              },
            },
            breakpoint_mean: {
              title: i18n.t("Breakpoint Mean"),
              description: i18n.t("The mean of the breakpoint for balloon risk."),
              type: "number",
              default: 64.5,
              minimum: 1,
              maximum: 100,
              "ui:grid": {
                xs: 4,
              },
            },
            breakpoint_std: {
              title: i18n.t("Breakpoint Standard Deviation"),
              description: i18n.t("The standard deviation of the breakpoint for balloon risk."),
              type: "number",
              default: 37,
              maximum: 100,
              minimum: 1,
              "ui:grid": {
                xs: 4,
              },
            },
          },
        },
      },
    },
    "spark.pop_the_bubbles": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
          required: ["bubble_count", "bubble_speed", "intertrial_duration", "bubble_duration"],
          properties: {
            bubble_count: {
              title: i18n.t("Bubble Count"),
              description: i18n.t("Multiple bubble counts per level."),
              type: "array",
              minItems: 3,
              maxItems: 3,
              items: {
                title: i18n.t("Level Count"),
                type: "number",
                minimum: 1,
                default: 80,
              },
              "ui:options": {
                addable: false,
                removable: false,
                orderable: false,
              },
              "ui:grid": {
                xs: 6,
              },
            },
            bubble_speed: {
              title: i18n.t("Bubble Speed"),
              description: i18n.t("Multiple bubble speeds per level."),
              type: "array",
              minItems: 3,
              maxItems: 3,
              items: {
                title: i18n.t("Level Speed"),
                type: "number",
                minimum: 1,
                default: 80,
              },
              "ui:options": {
                addable: false,
                removable: false,
                orderable: false,
              },
              "ui:grid": {
                xs: 6,
              },
            },
            intertrial_duration: {
              title: i18n.t("Intertrial Duration"),
              description: i18n.t("The duration between bubbles."),
              type: "number",
              minimum: 0,
              default: 0.5,
              "ui:grid": {
                xs: 6,
              },
            },
            bubble_duration: {
              title: i18n.t("Bubble Duration"),
              description: i18n.t("The duration the bubble appears on screen."),
              type: "number",
              minimum: 1,
              default: 1.0,
              "ui:grid": {
                xs: 6,
              },
            },
          },
        },
      },
    },
    "spark.memory_game": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
          properties: {
            foils: {
              title: i18n.t("Foils"),
              description: "3 from 9 :-  3x3 grid and 9 foils, 4 from 12 :-  3x3 grid and 12 foils.",
              type: "string",
              enum: [1, 2],
              enumNames: [i18n.t("3 from 9"), i18n.t("4 from 12")],
              default: 1,
              "ui:grid": {
                xs: 6,
              },
            },
            // encoding_trials: {
            //   title: i18n.t("Encoding trials"),
            //   description: "",
            //   type: "number",
            //   minimum: 0,
            //   maximum: 3,
            //   default: 3,
            //   readonly: true,
            //   "ui:grid": {
            //     xs: 3,
            //   },
            // },
            animation_interval: {
              title: i18n.t("Animation interval"),
              description: i18n.t("seconds"),
              type: "number",
              minimum: 1,
              maximum: 5,
              default: 2,
              "ui:grid": {
                xs: 3,
              },
            },
            animation_persistance: {
              title: i18n.t("Animation persistance"),
              description: i18n.t("seconds"),
              type: "number",
              minimum: 1,
              maximum: 5,
              default: 1,
              "ui:grid": {
                xs: 3,
              },
            },
          },
        },
      },
    },
    "spark.spatial_span": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
          required: ["reverse_tapping"],
          properties: {
            reverse_tapping: {
              title: i18n.t("Tap Order"),
              description: i18n.t("Whether taps are going forwards or backwards."),
              type: "boolean",
              enumNames: [i18n.t("Backward"), i18n.t("Forward")],
              default: true,
              "ui:widget": "radio",
            },
          },
        },
      },
    },
    "spark.recording": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
          required: ["microphone_text"],
          properties: {
            record_label: {
              title: i18n.t("Microphone record label"),
              description: i18n.t("This text will be shown for record"),
              type: "string",
              default: "Press the microphone to record",
            },
            rerecord_label: {
              title: i18n.t("Microphone re-record label"),
              description: i18n.t("To change the default label - 'Click upload or clear to record again'."),
              type: "string",
              default: "Click upload or clear to record again",
            },
          },
        },
      },
    },
    "spark.jewels_a": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
          required: [
            "mode",
            "variant",
            "beginner_seconds",
            "intermediate_seconds",
            "advanced_seconds",
            "expert_seconds",
            "diamond_count",
            "shape_count",
            "bonus_point_count",
          ],
          dependencies: {
            variant: {
              oneOf: [
                {
                  properties: {
                    variant: {
                      const: "trails_a",
                    },
                  },
                },
                {
                  properties: {
                    variant: {
                      const: "trails_b",
                    },
                    y_changes_in_level_count: {
                      title: i18n.t("Y changes in level count"),
                      description: "",
                      type: "number",
                      minimum: 0,
                      maximum: 25,
                      default: 2,
                      "ui:grid": {
                        xs: 3,
                      },
                    },
                    y_shape_count: {
                      title: i18n.t("Y shape count"),
                      description: "",
                      type: "number",
                      minimum: 0,
                      maximum: 4,
                      default: 1,
                      "ui:grid": {
                        xs: 3,
                      },
                    },
                  },
                },
              ],
            },
          },

          properties: {
            mode: {
              title: i18n.t("Mode"),
              description: i18n.t("The mode of the jewels game."),
              type: "number",
              enum: [1, 2, 3, 4],
              enumNames: [i18n.t("Beginner"), i18n.t("Intermediate"), i18n.t("Advanced"), i18n.t("Expert")],
              default: 1,
              "ui:grid": {
                xs: 6,
              },
            },
            variant: {
              title: i18n.t("Variant"),
              description: "The variant of the Jewels game (A or B).",
              type: "string",
              enum: ["trails_a", "trails_b"],
              enumNames: [i18n.t("Trails A"), i18n.t("Trails B")],
              default: "trails_a",
              "ui:grid": {
                xs: 6,
              },
            },
            beginner_seconds: {
              title: i18n.t("Beginner Duration"),
              description: i18n.t("The duration of a Jewels session on Beginner mode (in seconds)."),
              type: "number",
              minimum: 1,
              maximum: 300,
              default: 90,
              "ui:grid": {
                xs: 3,
              },
            },
            intermediate_seconds: {
              title: i18n.t("Intermediate Duration"),
              description: i18n.t("The duration of a Jewels session on Intermediate mode (in seconds)."),
              type: "number",
              minimum: 1,
              maximum: 300,
              default: 30,
              "ui:grid": {
                xs: 3,
              },
            },
            advanced_seconds: {
              title: i18n.t("Advanced Duration"),
              description: i18n.t("The duration of a Jewels session on Advanced mode (in seconds)."),
              type: "number",
              minimum: 1,
              maximum: 300,
              default: 25,
              "ui:grid": {
                xs: 3,
              },
            },
            expert_seconds: {
              title: i18n.t("Expert Duration"),
              description: i18n.t("The duration of a Jewels session on Expert mode (in seconds)."),
              type: "number",
              minimum: 1,
              maximum: 300,
              default: 15,
              "ui:grid": {
                xs: 3,
              },
            },
            diamond_count: {
              title: i18n.t("Initial Diamond Count"),
              description: i18n.t("The number of diamonds in Jewels game."),
              type: "number",
              minimum: 3,
              maximum: 25,
              default: 15,
              "ui:grid": {
                xs: 3,
              },
            },
            shape_count: {
              title: i18n.t("Initial Shape Count"),
              description: i18n.t("The number of shapes in Jewels game"),
              type: "number",
              minimum: 1,
              maximum: 3,
              default: 1,
              "ui:grid": {
                xs: 3,
              },
            },
            bonus_point_count: {
              title: i18n.t("Bonus points for next level"),
              description: "",
              type: "number",
              minimum: 0,
              maximum: 500,
              default: 50,
              "ui:grid": {
                xs: 6,
              },
            },
            x_changes_in_level_count: {
              title: i18n.t("X changes in level count"),
              description: "",
              type: "number",
              minimum: 0,
              maximum: 25,
              default: 1,
              "ui:grid": {
                xs: 3,
              },
            },
            x_diamond_count: {
              title: i18n.t("X diamond count"),
              description: "",
              type: "number",
              minimum: 0,
              maximum: 25,
              default: 4,
              "ui:grid": {
                xs: 3,
              },
            },
          },
        },
      },
    },
    "spark.jewels_b": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
          required: [
            "mode",
            "variant",
            "beginner_seconds",
            "intermediate_seconds",
            "advanced_seconds",
            "expert_seconds",
            "diamond_count",
            "shape_count",
            "bonus_point_count",
          ],
          dependencies: {
            variant: {
              oneOf: [
                {
                  properties: {
                    variant: {
                      const: "trails_a",
                    },
                  },
                },
                {
                  properties: {
                    variant: {
                      const: "trails_b",
                    },
                    y_changes_in_level_count: {
                      title: i18n.t("Y changes in level count"),
                      description: "",
                      type: "number",
                      minimum: 0,
                      maximum: 25,
                      default: 2,
                      "ui:grid": {
                        xs: 3,
                      },
                    },
                    y_shape_count: {
                      title: i18n.t("Y shape count"),
                      description: "",
                      type: "number",
                      minimum: 0,
                      maximum: 4,
                      default: 1,
                      "ui:grid": {
                        xs: 3,
                      },
                    },
                  },
                },
              ],
            },
          },
          properties: {
            mode: {
              title: i18n.t("Mode"),
              description: i18n.t("The mode of the jewels game."),
              type: "number",
              enum: [1, 2, 3, 4],
              enumNames: [i18n.t("Beginner"), i18n.t("Intermediate"), i18n.t("Advanced"), i18n.t("Expert")],
              default: 1,
              "ui:grid": {
                xs: 6,
              },
            },
            variant: {
              title: i18n.t("Variant"),
              description: i18n.t("The variant of the Jewels game (A or B)."),
              type: "string",
              enum: ["trails_a", "trails_b"],
              enumNames: [i18n.t("Trails A"), i18n.t("Trails B")],
              default: "trails_b",
              "ui:grid": {
                xs: 6,
              },
            },
            beginner_seconds: {
              title: i18n.t("Beginner Duration"),
              description: i18n.t("The duration of a Jewels session on Beginner mode (in seconds)."),
              type: "number",
              minimum: 1,
              maximum: 300,
              default: 90,
              "ui:grid": {
                xs: 3,
              },
            },
            intermediate_seconds: {
              title: i18n.t("Intermediate Duration"),
              description: i18n.t("The duration of a Jewels session on Intermediate mode (in seconds)."),
              type: "number",
              minimum: 1,
              maximum: 300,
              default: 30,
              "ui:grid": {
                xs: 3,
              },
            },
            advanced_seconds: {
              title: i18n.t("Advanced Duration"),
              description: i18n.t("The duration of a Jewels session on Advanced mode (in seconds)."),
              type: "number",
              minimum: 1,
              maximum: 300,
              default: 25,
              "ui:grid": {
                xs: 3,
              },
            },
            expert_seconds: {
              title: i18n.t("Expert Duration"),
              description: i18n.t("The duration of a Jewels session on Expert mode (in seconds)."),
              type: "number",
              minimum: 1,
              maximum: 300,
              default: 15,
              "ui:grid": {
                xs: 3,
              },
            },
            diamond_count: {
              title: i18n.t("Initial Diamond Count"),
              description: i18n.t("The number diamonds in Jewels game"),
              type: "number",
              minimum: 3,
              maximum: 25,
              default: 15,
              "ui:grid": {
                xs: 3,
              },
            },
            shape_count: {
              title: i18n.t("Initial Shape Count"),
              description: i18n.t("The number of shapes in Jewels game"),
              type: "number",
              minimum: 1,
              maximum: 3,
              default: 1,
              "ui:grid": {
                xs: 3,
              },
            },
            bonus_point_count: {
              title: i18n.t("Bonus points for next level"),
              description: "",
              type: "number",
              minimum: 0,
              maximum: 500,
              default: 50,
              "ui:grid": {
                xs: 6,
              },
            },
            x_changes_in_level_count: {
              title: i18n.t("X changes in level count"),
              description: "",
              type: "number",
              minimum: 0,
              maximum: 25,
              default: 1,
              "ui:grid": {
                xs: 3,
              },
            },
            x_diamond_count: {
              title: i18n.t("X diamond count"),
              description: "",
              type: "number",
              minimum: 0,
              maximum: 25,
              default: 4,
              "ui:grid": {
                xs: 3,
              },
            },
          },
        },
      },
    },

    "spark.survey": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Survey Questions"),
          description: i18n.t("Configure questions, parameters, and options."),
          type: "array",

          items: {
            required: ["text", "type"],

            type: "object",
            dependencies: {
              type: {
                oneOf: [
                  {
                    properties: {
                      type: {
                        enum: ["text", "boolean", "short_answer", "likert", "matrix"],
                      },
                    },
                  },
                  {
                    properties: {
                      type: {
                        enum: ["list", "multiselect"],
                      },
                      options: {
                        type: "array",
                        title: i18n.t("Response Options"),
                        minItems: 1,
                        items: {
                          type: "object",
                          properties: {
                            id: {
                              title: i18n.t("Option ID"),
                              type: "string",
                              default: "",
                            },
                            value: {
                              title: i18n.t("Option Text"),
                              type: "string",
                              minLength: 1,
                              default: "",
                            },
                            description: {
                              title: i18n.t("Option Description"),
                              type: "string",
                              default: "",
                            },
                          },
                        },
                      },
                    },
                    required: ["options"],
                  },
                  {
                    properties: {
                      type: {
                        enum: ["time"],
                      },
                      options: {
                        type: "array",
                        title: i18n.t("Response Options"),
                        minItems: 1,
                        maxItems: 1,
                        items: {
                          type: "object",
                          properties: {
                            value: {
                              title: "Time pattern",
                              type: "string",
                              enum: ["standard", "military"],
                              enumNames: [i18n.t("STANDARD TIME"), i18n.t("MILITARY TIME")],
                              default: "standard",
                            },
                          },
                        },
                      },
                    },
                  },
                  {
                    properties: {
                      type: {
                        enum: ["slider", "rating"],
                      },
                      options: {
                        type: "array",
                        title: i18n.t("Response Options"),
                        minItems: 1,
                        items: {
                          type: "object",
                          properties: {
                            value: {
                              title: i18n.t("Option Text (Numerical)"),
                              type: "number",
                              default: 0,
                            },
                            description: {
                              title: i18n.t("Option Description"),
                              type: "string",
                              default: "",
                            },
                          },
                        },
                      },
                    },
                    required: ["options"],
                  },
                ],
              },
            },
            properties: {
              human_id: {
                type: "string",
                title: i18n.t("ID (used in expressions)"),
                default: "",
              },
              type: {
                type: "string",
                title: i18n.t("Question Type"),
                enum: [
                  "text",
                  "boolean",
                  "list",
                  "multiselect",
                  "slider",
                  "short",
                  "rating",
                  "time",
                  "likert",
                  "matrix",
                ],
                enumNames: [
                  i18n.t("Text"),
                  i18n.t("Boolean"),
                  i18n.t("List"),
                  i18n.t("Multi-Select"),
                  i18n.t("Slider"),
                  i18n.t("Short Answer"),
                  i18n.t("Rating"),
                  i18n.t("Time"),
                  i18n.t("Likert"),
                  i18n.t("Matrix"),
                ],
                default: "text",
              },
              text: {
                type: "string",
                title: i18n.t("Question Text"),
                minLength: 1,
                default: "",
              },
              required: {
                title: i18n.t("Required"),
                type: "boolean",
                default: true,
              },
              description: {
                type: "string",
                title: i18n.t("Question Description"),
                default: "",
              },
              expression: {
                type: "string",
                title: i18n.t("Logical expression"),
                default: "",
              },
            },
          },
        },
      },
    },
    "spark.survey.hidden": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Survey Questions"),
          description: i18n.t("Configure questions, parameters, and options."),
          type: "array",
          items: {
            required: ["text", "type"],
            type: "object",
            dependencies: {
              type: {
                oneOf: [
                  {
                    properties: {
                      type: {
                        enum: ["text", "boolean", "short_answer", "likert", "matrix"],
                      },
                    },
                  },
                  {
                    properties: {
                      type: {
                        enum: ["list", "multiselect"],
                      },
                      options: {
                        type: "array",
                        title: i18n.t("Response Options"),
                        minItems: 1,
                        items: {
                          type: "object",
                          properties: {
                            id: {
                              title: i18n.t("Option ID"),
                              type: "string",
                              default: "",
                            },
                            value: {
                              title: i18n.t("Option Text"),
                              type: "string",
                              minLength: 1,
                              default: "",
                            },
                            description: {
                              title: i18n.t("Option Description"),
                              type: "string",
                              default: "",
                            },
                          },
                        },
                      },
                    },
                    required: ["options"],
                  },
                  {
                    properties: {
                      type: {
                        enum: ["time"],
                      },
                      options: {
                        type: "array",
                        title: i18n.t("Response Options"),
                        minItems: 1,
                        maxItems: 1,
                        items: {
                          type: "object",
                          properties: {
                            value: {
                              title: "Time pattern",
                              type: "string",
                              enum: ["standard", "military"],
                              enumNames: [i18n.t("STANDARD TIME"), i18n.t("MILITARY TIME")],
                              default: "standard",
                            },
                          },
                        },
                      },
                    },
                  },
                  {
                    properties: {
                      type: {
                        enum: ["slider", "rating"],
                      },
                      options: {
                        type: "array",
                        title: i18n.t("Response Options"),
                        minItems: 1,
                        items: {
                          type: "object",
                          properties: {
                            value: {
                              title: i18n.t("Option Text (Numerical)"),
                              type: "number",
                              default: 0,
                            },
                            description: {
                              title: i18n.t("Option Description"),
                              type: "string",
                              default: "",
                            },
                          },
                        },
                      },
                    },
                    required: ["options"],
                  },
                ],
              },
            },
            properties: {
              type: {
                type: "string",
                title: i18n.t("Question Type"),
                enum: [
                  "text",
                  "boolean",
                  "list",
                  "multiselect",
                  "slider",
                  "short",
                  "rating",
                  "time",
                  "likert",
                  "matrix",
                ],
                enumNames: [
                  i18n.t("Text"),
                  i18n.t("Boolean"),
                  i18n.t("List"),
                  i18n.t("Multi-Select"),
                  i18n.t("Slider"),
                  i18n.t("Short Answer"),
                  i18n.t("Rating"),
                  i18n.t("Time"),
                  i18n.t("Likert"),
                  i18n.t("Matrix"),
                ],
                default: "text",
              },
              text: {
                type: "string",
                title: i18n.t("Question Text"),
                minLength: 1,
                default: "",
              },
              required: {
                title: i18n.t("Required"),
                type: "boolean",
                default: true,
              },
              description: {
                type: "string",
                title: i18n.t("Question Description"),
                default: "",
              },
            },
          },
        },
      },
    },
    "spark.dbt_diary_card": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
          required: ["livingGoal", "targetEffective", "targetIneffective", "emotions"],
          properties: {
            livingGoal: {
              title: i18n.t("Life worth living goal"),
              description: i18n.t("300 max characters."),
              type: "string",
              default: "",
              "ui:widget": "textarea",
              "ui:options": {
                rows: 10,
              },
            },
            targetEffective: {
              title: i18n.t("Effective Target Behaviors"),
              type: "array",
              items: {
                type: "object",
                required: ["target", "measure"],
                properties: {
                  target: {
                    title: i18n.t("Behavior name"),
                    type: "string",
                    minLength: 1,
                  },
                  measure: {
                    title: "Measure of Action",
                    type: "string",
                    minLength: 1,
                    examples: [i18n.t("Times"), i18n.t("Hours"), i18n.t("Minutes"), i18n.t("Amount")],
                  },
                },
              },
            },
            targetIneffective: {
              title: i18n.t("Ineffective Target Behaviors"),
              type: "array",
              items: {
                type: "object",
                required: ["target", "measure"],
                properties: {
                  target: {
                    title: i18n.t("Behavior name"),
                    type: "string",
                    minLength: 1,
                  },
                  measure: {
                    title: i18n.t("Measure of action"),
                    type: "string",
                    minLength: 1,
                    examples: [i18n.t("Times"), i18n.t("Hours"), i18n.t("Minutes"), i18n.t("Amount")],
                  },
                },
              },
            },
            emotions: {
              title: i18n.t("Emotions"),
              description: i18n.t("Both good and bad emotions."),
              type: "array",
              items: {
                type: "object",
                properties: {
                  emotion: {
                    title: i18n.t("Emotions"),
                    type: "string",
                    minLength: 1,
                  },
                },
              },
            },
          },
        },
      },
    },
    "spark.cats_and_dogs": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
        },
      },
    },
    "spark.cats_and_dogs_new": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
        },
      },
    },
    "spark.journal": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
        },
      },
    },
    "spark.scratch_image": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
          required: ["threshold"],
          properties: {
            threshold: {
              title: i18n.t("Threshold"),
              description: i18n.t("The scratch threshold percentage."),
              type: "number",
              minimum: 1,
              maximum: 100,
              default: 80,
            },
          },
        },
      },
    },
    "spark.goals": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
          required: ["unit", "value"],
          properties: {
            unit: {
              title: i18n.t("Unit"),
              description: "",
              type: "string",
              default: "",
            },
            value: {
              title: i18n.t("Value"),
              description: "",
              type: "number",
            },
          },
        },
      },
    },
    "spark.medications": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
          required: ["unit", "value"],
          properties: {
            unit: {
              title: i18n.t("Unit"),
              description: "",
              type: "string",
              default: "",
            },
            value: {
              title: i18n.t("Value"),
              description: "",
              type: "number",
            },
          },
        },
      },
    },
    "spark.tips": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Tip Details"),
          type: "array",
          items: {
            type: "object",
            required: ["title", "text"],
            minItems: 1,
            properties: {
              title: {
                title: i18n.t("Tips Title"),
                type: "string",
                minLength: 1,
              },
              text: {
                title: i18n.t("Tips Description"),
                type: "string",
                minLength: 1,
                "ui:widget": "textarea",
                "ui:options": {
                  rows: 5,
                },
              },
              image: {
                title: i18n.t("Tips Image"),
                description: i18n.t(
                  "Images should be in the format .jpeg/.png/.gif/.svg and the size should not exceed 4 MB."
                ),
                type: "string",
                format: "data-url",
                "ui:widget": "file",
                "ui:options": {
                  accept: ".gif,.jpg,.png,.svg",
                },
              },
            },
          },
        },
      },
    },
    "spark.breathe": {
      type: "object",
      properties: {
        settings: {
          title: i18n.t("Activity Settings"),
          type: "object",
          properties: {
            audio_url: {
              title: i18n.t("Remote Audio URL"),
              description: i18n.t("Do not provide an audio URL AND upload audio below."),
              type: "string",
              format: "url",
            },
            audio: {
              title: i18n.t(""),
              description: i18n.t("Do not upload audio AND provide an audio URL above."),
              type: "string",
              format: "data-url",
              "ui:options": {
                accept: [".mp3", ".ogg", ".wav"],
              },
            },
          },
        },
      },
    },
  }
}

// Splice a raw Activity object with its ActivityDescription object.
export function spliceActivity({ raw, tag }) {
  return {
    id: raw.id,
    study_id: raw.study_id,
    category: raw.category,
    spec: raw.spec,
    name: raw.name,
    description: raw.description,
    photo: tag?.photo,
    streak: tag?.streak,
    schedule: raw.schedule,
    settings: !Array.isArray(raw.settings)
      ? raw.settings
      : raw.settings.map((question, idx) => ({
          id: question.id ?? undefined,
          text:
            /* TODO duplicate splice activity, the duplicate uses language based linking and original is for survey creation 
                i18n.language === "nl" ? (question.textNL === undefined ? question.text : question.textNL) 
              : i18n.language == "es-ES" ? (question.textES === undefined ? question.text : question.textES) 
              : i18n.language == "hi-IN" ? (question.textHI === undefined ? question.text : question.textHI) 
              :*/ question.text,
          type: question.type,
          required: question.required ?? false,
          description: question.description,
          options:
            question.options === null
              ? null
              : question.type !== "matrix" && question.type !== "time"
              ? question.options?.map((z, idx2) => ({
                  id: z.id,
                  value: z.value,
                  description: z.description,
                  human_id: z.human_id,
                }))
              : question.options,
          human_id: question.human_id,
          conditional_logic: question.conditional_logic,
          condition: question.condition ?? undefined,
          children: question.type === "group" ? question.children : undefined,
          time_format: question.time_format,
        })),
  }
}

// Splice a raw Activity object with its ActivityDescription object.
export function spliceActivityServe({ raw, tag }) {
  return {
    id: raw.id,
    study_id: raw.study_id,
    category: raw.category,
    spec: raw.spec,
    name: raw.name,
    description: raw.description,
    photo: tag?.photo,
    streak: tag?.streak,
    schedule: raw.schedule,
    settings: !Array.isArray(raw.settings)
      ? raw.settings
      : raw.settings.map((question, idx) => ({
          text:
            i18n.language === "nl"
              ? question.textNL === undefined
                ? question.text
                : question.textNL
              : i18n.language == "es-ES"
              ? question.textES === undefined
                ? question.text
                : question.textES
              : i18n.language == "hi-IN"
              ? question.textHI === undefined
                ? question.text
                : question.textHI
              : question.text,
          type: question.type,
          required: question.required ?? false,
          description:
            i18n.language === "nl"
              ? question.descriptionNL === undefined
                ? question.description
                : question.descriptionNL
              : i18n.language == "es-ES"
              ? question.descriptionES === undefined
                ? question.description
                : question.descriptionES
              : i18n.language == "hi-IN"
              ? question.descriptionHI === undefined
                ? question.description
                : question.descriptionHI
              : question.description,
          options:
            question.options === null
              ? null
              : question.type !== "matrix" && question.type !== "time"
              ? question.options?.map((z, idx2) => ({
                  value: z,
                  description: tag?.questions?.[idx]?.options?.[idx2],
                }))
              : question.options,
        })),
  }
}

// Un-splice an object into its raw Tips Activity object
export function unspliceTipsActivity(x) {
  return {
    raw: {
      id: x.id,
      name: x.name,
      spec: "spark.tips",
      icon: x.icon,
      schedule: x.schedule,
      settings: x.settings,
      studyID: x.studyID,
      category: x.category,
    },
  }
}

// Un-splice an object into its raw Activity object and ActivityDescription object.
export function unspliceActivity(x) {
  return {
    raw: {
      id: x.id,
      study_id: x.study_id,
      category: x.category,
      spec: x.spec == "spark.survey" || x.spec == "spark.survey.hidden" || "group.survey" ? x.spec : "spark.survey",
      name: x.name,
      description: x.description,
      schedule: x.schedule,
      settings: (x.settings && Array.isArray(x.settings) ? x.settings : [])?.map((y) => ({
        id: y?.id ?? undefined,
        text: y?.text,
        description: y?.description,
        type: y?.type,
        options:
          y?.options === null
            ? null
            : y?.type !== "matrix" && y?.type !== "time"
            ? y?.options?.map((z) => z) //TODO: faalt dit?
            : y?.options,
        required: y?.required ?? false,
        human_id: y?.human_id,
      })),
    },
    tag: {
      description: x.description,
      photo: x.photo,
      streak: x.streak,
      questions: (x.settings && Array.isArray(x.settings) ? x.settings : [])?.map((y) => ({
        multiselect: y?.type,
        description: y?.description,
        options:
          y?.options === null
            ? null
            : y?.type !== "matrix" && y?.type !== "time"
            ? y?.options?.map((z) => z?.description ?? "")
            : null,
      })),
    },
  }
}

export function unspliceCTActivity(x) {
  return {
    raw: {
      id: x.id,
      spec: x.spec,
      name: x.name,
      description: x.description,
      schedule: x.schedule,
      settings: x.settings,
      category: x.category,
    },
    tag: {
      description: x.description,
      photo: x.photo,
      streak: x.streak,
    },
  }
}

export function spliceCTActivity({ raw, tag }) {
  return {
    id: raw.id,
    study_id: raw.study_id,
    spec: raw.spec,
    name: raw.name,
    description: raw.description,
    photo: tag?.photo,
    streak: tag?.streak,
    schedule: raw.schedule,
    settings: raw.settings,
    category: raw.category,
  }
}

// Create a new Activity object & survey descriptions if set.
export async function saveTipActivity(x) {
  const { raw } = unspliceTipsActivity(x)
  let result
  if (!x.id && x.name) {
    result = (await SPARK.Activity.create(x.studyID, raw)) as any
    await SPARK.Type.setAttachment(result.data, "me", "spark.dashboard.activity_details", {
      photo: x.icon,
      streak: x.streak,
    })
  } else {
    result = (await SPARK.Activity.update(x.id, {
      name: x.name,
      settings: x.settings,
    })) as any
    await SPARK.Type.setAttachment(x.id, "me", "spark.dashboard.activity_details", {
      photo: x.icon,
      streak: x.streak,
    })
  }
  return result
}

export async function saveCTestActivity(x) {
  let newItem = (await SPARK.Activity.create(x.studyID, x)) as any
  await SPARK.Type.setAttachment(newItem.data, "me", "spark.dashboard.activity_details", {
    description: x.description,
    photo: x.photo,
    streak: x.streak,
  })
  return newItem
}

export async function saveSurveyActivity(x) {
  const expressions = x.settings.filter((q) => q.expression !== "")

  let newItem

  if (expressions.length > 0) {
    let { activities, triggers } = await saveGroupSurveyQuestions(x.settings, x.studyID)

    // Save the group.survey
    x.spec = "group.survey"
    let { raw, tag } = unspliceActivity(x)
    raw.settings = activities.map((a) => {
      return a.id
    }) // Set conditional.question 's under this group.survey
    newItem = (await SPARK.Activity.create(x.studyID, raw)) as any
    await SPARK.Type.setAttachment(newItem.data, "me", "spark.dashboard.survey_description", tag)

    // Save the triggers/conditions for the questions of this group.survey
    for (let t of triggers) {
      await saveGroupTrigger(newItem.data, t)
    }
  } else {
    let { raw, tag } = unspliceActivity(x)
    newItem = (await SPARK.Activity.create(x.studyID, raw)) as any
    await SPARK.Type.setAttachment(newItem.data, "me", "spark.dashboard.survey_description", tag)
  }
  return newItem
}

async function saveGroupSurveyQuestions(x, groupSurveyId) {
  let activities: any[] = []
  let triggers: any[] = []
  for (let question of x) {
    let activity = await processSurveyQuestion(question, groupSurveyId)
    activities.push(activity)

    let trigger = await processSurveyTrigger(question, x, activities)
    if (!!trigger) {
      triggers.push(trigger)
    }
  }
  return { activities, triggers }
}

async function processSurveyQuestion(question, groupSurveyId) {
  // Prepare question object
  const raw = {
    name: null,
    spec: "group.survey.question",
    settings: [
      {
        text: question.text,
        description: question.description,
        type: question.type,
        required: question.required,
        human_id: question.human_id,
        options:
          question.options === null
            ? null
            : question.type !== "matrix" && question.type !== "time" // TODO: still correct if we implement this with the conditional expression?
            ? question.options?.map((z, idx2) => ({
                id: z.id,
                value: z.value,
                description: z.description,
              }))
            : question.options,
      },
    ],
    category: [],
  }

  if (question.id) {
    // Update existing question
    const data = (await SPARK.Activity.update(question.id, raw)) as any
    if (!data.error) {
      return {
        id: question.id,
        text: question.text,
        human_id: question.human_id,
        type: question.type,
        options: question.options,
      }
    }
  } else {
    // Create a new question
    const res = (await SPARK.Activity.create(groupSurveyId, raw)) as any
    if (res.data) {
      return {
        id: res.data,
        text: question.text,
        human_id: question.human_id,
        type: question.type,
        options: question.options,
      }
    }
  }
}

async function processSurveyTrigger(question, settings, activities) {
  if (!question || question.expression === "") {
    return null
  }

  if (!question.expression) {
    return null
  } else {
    const extraction = extractConditions(question, activities)
    const activityToTrigger = activities.find((activity) => activity.text === question.text)

    if (!activityToTrigger) {
      return null
    }

    return {
      name: "question.trigger",
      conditions: JSON.stringify(extraction),
      trigger: activityToTrigger.id,
    }
  }
}

async function saveGroupTrigger(activity_group_id, data) {
  //TODO:FIXME: do this with spark-js sdk
  const body = data
  const url =
    (process.env.REACT_APP_ENV === "production" ? "https" : "http") +
    "://" +
    (!!SPARK.Auth._auth.serverAddress ? SPARK.Auth._auth.serverAddress : "api.spark.digital") +
    `/activity/${activity_group_id}/trigger`
  let res = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
    },
    body: JSON.stringify(body),
  })
  return res.json()
}

export const getGroupSurveyQuestions = async (question_ids: [string]) => {
  // TODO: check if spec === "group.survey.question"
  const settings = await Promise.all(
    question_ids.map(async (question) => {
      const q = await SPARK.Activity.view(question)
      const questionObj = q?.settings[0]
      if (questionObj) {
        questionObj.id = question
        return questionObj
      }
      return null
    })
  )

  return settings.filter((question) => question !== null)
}

export const mergeQuestionsAndTriggers = async (questions, triggers) => {
  for (const trigger of triggers) {
    const tree = jsonStringToExpressionTree(trigger.conditions)

    await SPARK.Activity.view(trigger.trigger).then((toBeTriggered) => {
      const id = questions.findIndex((s) => s.text === toBeTriggered.settings[0].text)
      for (const condition of trigger.conditions) {
        questions[id].expression = expressionTreeToString(tree)
      }
    })
  }
  return questions
}

export const updateGroupSurvey = async (x: any) => {
  const activities = []
  const triggers = []
  for (const question of x.settings) {
    const activity = await processSurveyQuestion(question, x.id)
    activities.push(activity)

    const trigger = await processSurveyTrigger(question, x.settings, activities)
    if (trigger) {
      triggers.push(trigger)
    }
  }

  let { raw, tag } = unspliceActivity(x)
  raw.settings = activities.map((a) => {
    return a.id
  }) // Set conditional.question 's under this group.survey

  await SPARK.Activity.update(x.id, raw)
  await SPARK.Type.setAttachment(x.id, "me", "spark.dashboard.survey_description", tag)

  // Remove any old triggers
  const oldTriggers = (await fetchActivityTriggers(x.id)).data
  for (let trigger of oldTriggers) {
    deleteTrigger(trigger.id)
  }

  // Save the triggers/conditions for the questions of this group.survey
  for (let t of triggers) {
    await saveGroupTrigger(x.id, t)
  }
}

export async function fetchActivityTriggers(activityId) {
  const url =
    (process.env.REACT_APP_ENV === "production" ? "https" : "http") +
    "://" +
    (!!SPARK.Auth._auth.serverAddress ? SPARK.Auth._auth.serverAddress : "api.spark.digital") +
    `/activity/${activityId}/trigger`
  let res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
    },
  })
  return res.json()
}

const deleteTrigger = async (triggerId) => {
  const url =
    (process.env.REACT_APP_ENV === "production" ? "https" : "http") +
    "://" +
    (!!SPARK.Auth._auth.serverAddress ? SPARK.Auth._auth.serverAddress : "api.spark.digital") +
    `/trigger/${triggerId}/`
  let res = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${SPARK.Auth._auth.id}:${SPARK.Auth._auth.password}`,
    },
  })
  return res.json()
}

export async function getActivitySpec(spec) {
  let result = await SPARK.ActivitySpec.view(spec)
  return result
}

export async function getDefaultTab(spec) {
  let activitySpec = await getActivitySpec(spec)
  if (!!activitySpec?.category) {
    return activitySpec?.category[0]
  } else {
    if (
      games.includes(spec) ||
      spec === "spark.group" ||
      spec === "spark.dbt_diary_card" ||
      spec === "spark.recording" ||
      spec === "spark.survey"
    ) {
      return "assess"
    }
    if (
      spec === "spark.goals" ||
      spec === "spark.medications" ||
      spec === "spark.journal" ||
      spec === "spark.breathe" ||
      spec === "spark.scratch_image"
    ) {
      return "manage"
    }
    if (spec === "spark.tips") return "learn"
  }
}

export const updateSchedule = async (activity) => {
  await SPARK.Activity.update(activity.id, activity)
  Service.update("activities", { activities: [{ schedule: activity.schedule, id: activity.id }] }, "schedule", "id")
}
// Commit an update to an Activity object (ONLY DESCRIPTIONS).
export async function updateActivityData(x, isDuplicated, selectedActivity) {
  let result
  if (!["spark.group", "spark.survey", "spark.survey.hidden", "spark.tips"].includes(x.spec)) {
    // Short-circuit for groups and CTests
    if (isDuplicated) {
      result = (await SPARK.Activity.create(x.studyID, x)) as any
      await SPARK.Type.setAttachment(result.data, "me", "spark.dashboard.activity_details", {
        description: x?.description ?? "",
        photo: x?.photo ?? "",
        streak: x?.streak ?? null,
      })
      return result
    } else {
      if (selectedActivity?.study_id !== x.studyID) {
        // let tag = await SPARK.Type.setAttachment(x.id, "me", "spark.dashboard.activity_details", null)
        // await SPARK.Activity.delete(x.id)
        // result = (await SPARK.Activity.create(x.studyID, x)) as any
        // await SPARK.Type.setAttachment(result.data, "me", "spark.dashboard.activity_details", {
        //   description: x?.description ?? "",
        //   photo: x?.photo ?? "",
        // })
      } else {
        result = (await SPARK.Activity.update(x.id, x)) as any
        await SPARK.Type.setAttachment(selectedActivity?.id, "me", "spark.dashboard.activity_details", {
          description: x.description,
          photo: x.photo,
          streak: x.streak,
        })
        return result
      }
    }
  } else if (x.spec === "spark.group" || x.spec === "spark.dbt_diary_card") {
    if (isDuplicated) {
      result = (await SPARK.Activity.create(x.studyID, x)) as any
      await SPARK.Type.setAttachment(result.data, "me", "spark.dashboard.activity_details", {
        description: x.description,
        photo: x.photo,
        streak: x.streak,
      })
      return result
    } else {
      result = (await SPARK.Activity.update(selectedActivity?.id, x)) as any

      await SPARK.Type.setAttachment(selectedActivity?.id, "me", "spark.dashboard.activity_details", {
        description: x.description,
        photo: x.photo,
        streak: x.streak,
      })
      return result
    }
  } else if (x.spec === "spark.survey" || x.spec === "spark.survey.hidden") {
    const { raw, tag } = unspliceActivity(x)
    if (isDuplicated) {
      result = (await SPARK.Activity.create(x.studyID, raw)) as any
      await SPARK.Type.setAttachment(result.data, "me", "spark.dashboard.survey_description", tag)
      return result
    } else {
      result = (await SPARK.Activity.update(selectedActivity?.id, raw)) as any
      await SPARK.Type.setAttachment(selectedActivity?.id, "me", "spark.dashboard.survey_description", tag)
      return result
    }
  } else if (x.spec === "spark.tips") {
    if (x.id === undefined) {
      let tipObj = {
        id: x.id,
        name: x.name,
        icon: x.icon,
        studyID: selectedActivity?.study_id,
        spec: "spark.tips",
        settings: x.settings,
        streak: x.streak,
        schedule: [],
        category: x.category,
      }
      result = await saveTipActivity(tipObj)
      return result
    } else {
      result = (await SPARK.Activity.update(selectedActivity?.id, x)) as any
      await SPARK.Type.setAttachment(selectedActivity?.id, "me", "spark.dashboard.activity_details", {
        photo: x.icon,
        streak: x.streak,
      })
      return result
    }
  }
}
export function addActivity(x, study) {
  Service.updateCount("studies", x.studyID, "activity_count")
  x["study_id"] = x.studyID
  x["study_name"] = study.name
  delete x["studyID"]
  Service.addData("activities", [x])
}
